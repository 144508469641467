import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import {
  Container,
  Paragraph,
  TitleWithImage,
  CircleImage,
} from "@wel-shy/portfolio-component-library";

import { Wrapper, SocialMediaWrapper } from "./styles";
import { TrelloGqlResult } from "../../models/TrelloGqlResult";
import { CV } from "../CV";
import { SocialMedia } from "../SocialMedia";

interface TrelloNode {
  name: string;
  content: string;
  medias: {
    url: string;
  };
}

export const Intro = () => {
  const {
    allTrelloCard: { edges },
  } = useStaticQuery(graphql`
    {
      allTrelloCard(
        filter: { list_name: { eq: "Header" } }
        sort: { fields: [index], order: ASC }
      ) {
        edges {
          node {
            name
            content
            medias {
              url
            }
          }
        }
      }
    }
  `) as TrelloGqlResult<TrelloNode>;

  const cards = edges.map(({ node }) => node);
  const getCard = (key: string) =>
    cards.find(({ name }) => name === key) || { content: "" };

  const title = getCard("[title]");
  const subTitle = getCard("[subtitle]");
  const statement = getCard("[statement]");

  const profileImage = (
    <CircleImage src="/images/profile.jpeg" alt="Profile picture" width={300} />
  );

  return (
    <Wrapper>
      <Container>
        <TitleWithImage
          titleText={title.content}
          subtitleText={subTitle.content}
          image={profileImage}
        >
          <CV />
          <SocialMediaWrapper>
            <SocialMedia />
          </SocialMediaWrapper>
        </TitleWithImage>
      </Container>
      <Container>
        <Paragraph large>{statement.content}</Paragraph>
      </Container>
    </Wrapper>
  );
};
