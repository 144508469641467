module.exports = {
  defaultTitle: "Daniel Welsh",
  author: "Daniel Welsh",
  url: "https://dwelsh.uk",
  legalName: "Daniel Welsh",
  defaultDescription: "Software Engineer in Stockholm",
  socialLinks: {
    twitter: "http://www.twitter.com/wel__shy",
    github: "https://github.com/wel-shy",
    linkedin: "https://www.linkedin.com/in/daniel-welsh-914776150/",
    instagram: "https://instagram.com/wel__shy",
  },
  themeColor: "#6b63ff",
  backgroundColor: "#6b63ff",
  social: {
    twitter: "@wel__shy",
    instagram: "@wel__shy",
  },
  address: {
    city: "Stockholm",
    region: "Stockholm County",
    country: "Sweden",
  },
  contact: {
    email: "e@dwelsh.uk",
  },
  foundingDate: "2019",
  recaptcha_key: "6Lcs6lQUAAAAAEwhNH2IsobIe2csdda4TU3efpMN",
};
