import styled from "styled-components";

export const Wrapper = styled.div`
  padding-bottom: 4rem;
  background-image: url("../illustrations/overlay.svg");
  background-size: contain;
  background-position: right top;
  background-repeat: no-repeat;
`;

export const SocialMediaWrapper = styled.div`
  margin-top: 2rem;
`;
