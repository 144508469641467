import styled from "styled-components";
import { ITheme } from "@wel-shy/portfolio-component-library";

export const Wrapper = styled.div`
  background-image: url("../illustrations/footer.svg");
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
`;

export const Links = styled.div`
  margin-top: 1em;
`;

export const Details = styled.div`
  padding-top: 6rem;
  @media (max-width: ${({ theme }) => (theme as ITheme).sizes.screen.s}px) {
    margin-bottom: 2rem;
  }
`;
