import React from "react";
import { Container, Link, H2 } from "@wel-shy/portfolio-component-library";
import { Wrapper, Details, Links } from "./styles";
import { Event } from "../../utils/tracking";
import { SocialMedia } from "../SocialMedia";

export const Footer = () => (
  <Wrapper>
    <Container>
      <Details>
        <H2>Daniel Welsh</H2>
        <span>
          ©{" "}
          <Link
            href="https://dwelsh.uk"
            rel="noopener noreferrer"
            target="_blank"
          >
            Daniel Welsh
          </Link>
          | {new Date().getFullYear()}
        </span>
        <br />
        <br />
        <span>
          Template from{" "}
          <Link href="https://portfolio.smakosh.com/">Smakosh</Link>
        </span>
        <br />
        <span>
          Icons from <Link href="https://fontawesome.com/">Font Awesome</Link>{" "}
          under{" "}
          <Link href="https://creativecommons.org/licenses/by/4.0/">
            CC BY 4.0
          </Link>
        </span>
        <br />
        <span>
          Illustrations from <Link href="https://undraw.co/">Undraw</Link>
        </span>
        <br />
        <br />
        <span>
          Project available on{" "}
          <Link
            onClick={() => Event("Repositories", "click", "portfolio")}
            href="https://github.com/wel-shy/portfolio"
          >
            GitHub
          </Link>
        </span>
      </Details>
      <Links>
        <SocialMedia />
      </Links>
    </Container>
  </Wrapper>
);
