import React from "react";
import {
  LightTheme,
  ThemeProvider,
} from "@wel-shy/portfolio-component-library";
import { Footer } from "../Footer";
import { SEO } from "../SEO";
import { Navigation } from "../Navigation";

interface PageWrapperProps {
  children: any;
  pageTitle: string;
}

export const PageWrapper = ({ children, pageTitle }: PageWrapperProps) => {
  return (
    <>
      <SEO title={pageTitle} />
      <ThemeProvider theme={LightTheme}>
        <Navigation />
        {children}
        <Footer />
      </ThemeProvider>
    </>
  );
};
