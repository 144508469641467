import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Link } from "@wel-shy/portfolio-component-library";
import SVG from "react-inlinesvg";

import { Links } from "./styles";
import { TrelloGqlResult } from "../../models";
import { Event } from "../../utils/tracking";
import GitHub from "../../../static/icons/github.svg";
import Email from "../../../static/icons/email.svg";
import LinkedIn from "../../../static/icons/linkedin.svg";
import Twitter from "../../../static/icons/twitter.svg";
import Instagram from "../../../static/icons/instagram.svg";

const icons = [
  {
    name: "Email",
    icon: Email,
  },
  {
    name: "GitHub",
    icon: GitHub,
  },
  {
    name: "Instagram",
    icon: Instagram,
  },
  {
    name: "LinkedIn",
    icon: LinkedIn,
  },
  {
    name: "Twitter",
    icon: Twitter,
  },
];

interface TrelloNode {
  id: string;
  name: string;
  content: string;
  childCardMedia: {
    localFile: {
      url: string;
    };
  };
}

export const SocialMedia = () => {
  const {
    allTrelloCard: { edges },
  } = useStaticQuery(graphql`
    {
      allTrelloCard(
        filter: { list_name: { eq: "Social Media" } }
        sort: { fields: [index], order: ASC }
      ) {
        edges {
          node {
            id
            name
            content
            childCardMedia {
              localFile {
                url
              }
            }
          }
        }
      }
    }
  `) as TrelloGqlResult<TrelloNode>;

  const socialMedia = edges.map((edge) => edge.node);
  const mediaLinks = socialMedia.map((media) => {
    const icon = icons.find((icon) => icon.name === media.name);
    const linkContent: string | JSX.Element = icon ? (
      <SVG width="24" src={icon.icon} />
    ) : (
      media.name
    );

    return (
      <Link
        key={media.id}
        href={media.content}
        target="_blank"
        rel="noopener noreferrer"
        aria-label={`follow me on ${media.name}`}
        onClick={() => Event("Social Links", "click", media.name)}
      >
        {linkContent}
      </Link>
    );
  });

  return <Links>{mediaLinks}</Links>;
};
