import React from "react";
import { Button } from "@wel-shy/portfolio-component-library";
import { useStaticQuery, graphql } from "gatsby";
import { Event } from "../../utils/tracking";
import { TrelloGqlResult } from "../../models";

interface TrelloNode {
  name: string;
  content: string;
  medias: {
    url: string;
  }[];
}

export const CV = () => {
  const {
    allTrelloCard: { edges },
  } = useStaticQuery(graphql`
    {
      allTrelloCard(
        filter: { list_name: { eq: "CV" } }
        sort: { fields: [index], order: ASC }
      ) {
        edges {
          node {
            name
            content
            medias {
              url
            }
          }
        }
      }
    }
  `) as TrelloGqlResult<TrelloNode>;

  const buttonNode = edges.find((edge) => edge.node.name === "[button]");
  const fileNode = edges.find((edge) => edge.node.name === "[file]");
  if (!buttonNode || !fileNode) {
    return null;
  }

  const {
    node: { content },
  } = buttonNode;
  const {
    node: {
      medias: [{ url }],
    },
  } = fileNode;

  return (
    <Button
      onClick={() => {
        Event("CV", "download", "Download CV");
        window.open(url, "_blank");
      }}
      buttonType="primary"
    >
      {content}
    </Button>
  );
};
