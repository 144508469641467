import React from "react";
import { NavigationBar, NavItem } from "@wel-shy/portfolio-component-library";
import { graphql, useStaticQuery } from "gatsby";

export const Navigation = () => {
  const {
    allTrelloCard: { distinct },
  } = useStaticQuery(graphql`
		{
			allTrelloCard(filter: {list_name: {regex: "/\\[section\\]/"}}) {
				distinct(field: list_name)
			}
		}
	`) as { allTrelloCard: { distinct: string[] } };

  const items = distinct
    .map((item) => {
      const listName = item.replace(/\[section\]/, "");

      return {
        name: listName,
        id: `#${listName.toLowerCase()}`,
      };
    })
    .filter(({ name }) => name !== "Instagram");

  const Links = items.map(({ name, id }) => (
    <NavItem href={`/${id}`} active={false} key={`${name}-${id}`}>
      {name}
    </NavItem>
  ));

  return (
    <NavigationBar
      brand={
        <NavItem href="/" active={false}>
          Daniel Welsh
        </NavItem>
      }
      rightGroup={Links}
      closeIcon={<span>❌</span>}
      menuIcon={<span>🍔</span>}
    />
  );
};
