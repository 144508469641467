import styled from "styled-components";

export const Links = styled.div`
  display: flex;
  align-items: center;

  a {
    margin: 0 0.5rem;

    img {
      margin: 0;
    }
  }

  svg {
    ${(props) => props.theme.transition}

    path {
      fill: ${(props) => props.theme.colors.secondary};
      ${(props) => props.theme.transition}
    }

    :hover {
      path {
        fill: ${(props) => props.theme.colors.dark};
      }
    }
  }
`;
